<template>
<div class="scope">
    <div class="paymentSystem">
        <div class="mb-2 topic text-center"><b>{{topic}}</b><br></div>
        <hr>
        <div v-html="detail"></div>
    </div>
</div>
</template>

<script>
export default {
    mounted() {
        //document.title = 'จุดประสงค์การก่อตั้ง NL'
    },
    data() {
        return {
            topic: "จุดประสงค์การก่อตั้ง NL",
            detail: `
                        <div class="mb-2">เกี่ยวกับพวกเรา</div>
                        <div class="mb-2">แรกเริ่มเดิมที พวกเราเองก็เป็นหนึ่งในผู้อ่านนิยายที่มารวมตัวกัน</div>
                        <div class="mb-2">ถ้าพูดถึงนิยายกำลังภายในสมัยใหม่ที่เราอ่าน ก็คงเริ่มจาก พงศาวดาร.. .พี่เนี่ย แล้วก็ พี่เฟิง ,  พี่หยุน , เฮียเมิ่ง และเรื่องอื่นๆอีกมาก ซึมซับประสบการณ์ทั้งในฐานะผู้อ่านและผู้แปล ได้แลกเปลี่ยนความคิดเห็นจากผู้คนมากมาย เก็บคำตำหนิมาปรับปรุงแก้ไข ตั้งหัวข้อถกสปอยกันต่างๆนาๆ ทุกท่านอาจคิดว่าเราคงไม่เข้ามาอ่านคอมเม้น บอกเลยว่าทุกคอมเม้นทีมงานล้วนเข้าไปอ่านไม่ว่าจะหลบอยู่ใต้เนื้อหาหรือคอมเม้นในเพจ เราก็ตามไปเปิดดู ส่วนมากจะพบคอมเม้นว่า. . .ค้าง จากพี่น้องชาวโนเวล ถึงจะเป็นคำสั้นๆแต่ก็เป็นเหมือนกำลังใจให้กับพวกทีมงาน ส่วนคอมเม้น ขอวันละ 10 ตอน ถ้าทางคนแปลไหว ทางเราก็ลงไหวเช่นเดียวกัน ^^</div>
                        <div class="mb-2">ทีมงานของเราเหมือนได้คลุกคลีกับผู้อ่านชาวโนเวลมาโดยตลอด ดังนั้น ทุกปัญหา ทุกคำตำหนิ พวกเรายินดีน้อมรับฟัง และพร้อมจะปรับปรุงแก้ไข ส่วนไหนที่ผิดพลาด ทีมงานก็ต้องขออภัยไว้ ณ ที่นี่</div>
                        <div class="mb-2">โนเวลเลียว์ม ถือกำเนิดขึ้นมาเพราะต้องการให้รายย่อย นักเขียน ผู้แปลที่มีทุนน้อยระดมทุนทรัพย์เพื่อถือครองลิขสิทธิ์ โดยจะกำหนดราคาเป็นกลางและเป็นธรรม ที่พี่น้องชาวโนเวลยอมรับได้</div>
                        <div class="mb-2">เราจึงหวังอย่างยิ่งว่าจะได้รับความอนุเคราะห์จากพี่น้องชาวโนเวลทุกท่านในการช่วยผลักดันส่งเสริมเรา แม้แต่การช่วยเป็นกระบอกเสียงประชาสัมพันธ์นั่นก็นับเป็นความอนุเคราะห์จากพี่น้องชาวโนเวลทุกท่าน แม้คนอื่นๆอาจมองว่าการ กดไลค์ การกดแชร์ มันเป็นสิ่งเล็กน้อย แต่สำหรับเรามันมีค่าอย่างมาก</div>
                        <div class="mb-2">พวกเราจะพยายามเสาะหานิยายดีๆที่มีเนื้อหาสาระ เต็มไปด้วยความสนุก มาให้พี่ๆน้องๆชาวโนเวลได้ติดตามและเกิดความรู้สึกว่าคุ้มค่ากับเงินทุกบาทที่เป็นแรงสนับสนุนผลักดันให้ค่ายนิยายเล็กๆแห่งนี้ให้ก้าวต่อไปไ</div>
                        พวกเราทีมงานโนเวลเลียว์ม ขอขอบพระคุณพี่น้องชาวโนเวลทุกๆท่านจากใจจริง  
                    `
        }
    }
}
</script>

<style scoped>
.scope {
    padding: 3% 10% 3% 10%;
}

.paymentSystem {
    background-color: #ffffff;
    height: 57vh;
    overflow: scroll;
    border: 20px solid #ffffff;
}

.topic {
    font-size: 18px;
}

@media screen and (max-width: 500px) {
    .scope {
        padding: 2% 2% 2% 2%;
    }

    .paymentSystem {
        border: 10px solid #ffffff;
        font-size: 14px;
    }

    .topic {
        font-size: 13px;
    }
}

@media screen and (min-width: 1441px) {
    .scope {
        padding: 3% 20% 3% 20%;
    }
}
</style>
